/*
a style to attach buttons on the bottom of a select

do not add the `record-it-icon` class to the icons to keep them smaller

example for a custom-select with the record-it-input-field class:

  <div class="record-it-input-field">
    <custom-select></custom-select>
    <div class="record-it-select-sub-buttons">
      <button class="record-it-button record-it-button-inline-icon">
        <custom-icon icon-type="far" icon-name="fa-edit"></custom-icon>
        <span t="general.edit"></span>
      </button>

      <button class="record-it-button record-it-button-inline-icon">
        <custom-icon icon-type="far" icon-name="fa-plus-circle"></custom-icon>
        <span t="general.create"></span>
      </button>
    </div>
  </div>

*/

.record-it-select-sub-buttons {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  /* align button text with container */
  margin-left: calc(var(--record-it-button-vertical-padding) * -1);
  margin-right: calc(var(--record-it-button-vertical-padding) * -1);
}

.record-it-select-sub-buttons > .record-it-button {
  flex: 0 0 auto;
}
