/*

Usage example:
  <a href="javascript:void(0)" class="record-it-expandable-button">
    <div class="record-it-expandable-button-icon-wrapper">
      <custom-icon icon-type="fal"
                   icon-name="fa-minus-circle">
      </custom-icon>
    </div>
    <div>
      <span t="generalPages.showProcessAppointment.showMeasurePointLog"></span> <!-- if no sub text is needed, then the text can be directly placed here instead of needed to be wrapped in the span -->
      <div class="record-it-expandable-button-sub-text">...</div> <!-- optional -->
    </div>
  </a>
  <div show.to-view="_expanded" class="record-it-expandable-button-expandable-container">
    ...
    content
    ...

    <div class="record-it-expandable-button-expandable-container-footer">
      <button as-element="record-it-panel-close-button">
      </button>
    </div>
  </div>

*/

.record-it-expandable-button {
  display: flex;
  align-items: center;
  background-color: var(--record-it-color-white);
  color: var(--record-it-color-gray-800);
  height: 58px;
  margin: 11px 0;
  border-radius: 3px;
  box-shadow: 0 1px 4px 0 var(--record-it-color-gray-300);
  cursor: pointer;
}

.record-it-expandable-button-icon-wrapper {
  font-size: 32px;
  flex: 0 0 64px;
  text-align: center;
}

.record-it-expandable-button-sub-text {
  display: block;
  font-size: 13px;
  color: var(--record-it-color-gray-700);
}

.record-it-expandable-button-expandable-container {
  background-color: var(--record-it-color-gray-300);
  border: solid 1px rgba(226, 226, 226, 0.7);
  margin-top: -10px;
  margin-bottom: 10px;
  padding: 10px 10px 0px;
}

.record-it-expandable-button-expandable-container-bottom-padding {
  padding-bottom: 10px;
}

.record-it-expandable-button-expandable-container-footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 25px;
}
