.record-it-icon {
  display: inline-block;
  text-align: center;
  width: 24px;
  height: 24px;
  vertical-align: baseline;
  line-height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.record-it-icon-hover-orange:hover {
  color: var(--record-it-color-primary);
  cursor: pointer;
}

.record-it-icon-2x {
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.record-it-icon-52px {
  width: 52px;
  height: 52px;
  line-height: 52px;
  font-size: 52px;
}

.record-it-icon.fas,
.record-it-icon.fa,
.record-it-icon.far,
.record-it-icon.fal {
  font-size: 20px;
}

.record-it-icon-small {
  width: 12px;
  height: 12px;
  line-height: 12px;
}

.record-it-icon-small.fas,
.record-it-icon-small.fa,
.record-it-icon-small.far,
.record-it-icon-small.fal {
  font-size: 10px;
}

.record-it-icon-text-size,
.record-it-icon-text-size.fas,
.record-it-icon-text-size.fa,
.record-it-icon-text-size.far,
.record-it-icon-text-size.fal {
  width: 1em;
  height: 1em;
  line-height: 1em;
  font-size: 1em;
}

.record-it-icon-orange {
  color: var(--record-it-color-primary);
}

.record-it-interactive-icon {
  cursor: pointer;
  opacity: 0.5;
}

.record-it-interactive-icon:focus,
.record-it-interactive-icon:hover {
  opacity: 0.8;
}
