.camera-preview-active,
.camera-preview-active body {
  background-color: transparent;
}

.camera-preview-active #main-body header,
.camera-preview-active #main-body section,
.camera-preview-active [aurelia-app='main'] > * {
  visibility: hidden;
}

body > #video-container {
  position: fixed;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

body > #video-container > video {
  width: 100%;
  height: 100%;
  background: black;
}

.camera-preview-active
  [aurelia-app='main']
  > visibility-cutoff:last-of-type
  ~ * {
  visibility: unset;
}
