html,
body {
  min-height: 100vh;
  background: var(--record-it-color-gray-200);
}

#footer {
  background: #edecec;
}

.card {
  margin-bottom: 0;
}

.server-error {
  display: none;
  position: fixed;
  top: 0;
  width: 100%;
}

.red {
  color: red;
}

.heading {
  font-size: 1.1em;
}

.hide-file-input-container {
  position: relative;
}

.hide-file-input input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  font-size: 23px;
  height: 100%;
  width: 100%;
  direction: ltr;
  cursor: pointer;
}

.bordered-top {
  border-top: 1px solid #f0f0f0 !important;
}

.bordered-bottom {
  border-bottom: 1px solid #f0f0f0 !important;
}

.bg-white {
  background: #fff;
}

.input-sm {
  letter-spacing: 0.04em;
}

.input-inline {
  height: auto;
}

.entry-left-container {
  float: left;
  max-width: 30px;
  padding: 0;
  margin: 0 0 0 -7px;
  z-index: 1000;
}

.entry-left-icon {
  font-size: 20px;
  padding: 10px;
  margin-left: -10px;
}

#main-body.mobile .entry-left-icon {
  font-size: 25px;
  padding: 7px 10px;
}

.pointer {
  cursor: pointer;
}

@keyframes rotateSync {
  from {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, 750deg);
  }

  100% {
    transform-origin: center;
    transform: none;
    color: red;
  }
}

.rotateSync {
  animation-name: rotateSync;
}

@keyframes rotateSyncSimple {
  from {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, 360deg);
  }

  100% {
    transform-origin: center;
    transform: none;
  }
}

.rotateSyncSimple {
  animation: rotateSyncSimple 2s linear;
}

.dropdown-menu.open {
  position: relative;
}

.note-container textarea {
  width: 100%;
  max-width: 100%;
  height: 175px;
  border: 1px solid #f0f0f0;
}

.importnote {
  border: 1px solid black;
  padding: 3px;
  margin: 7px 10px;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-block;
  line-height: 1.3;
}

.importnote-set {
  border: 1px solid #545454;
  color: white;
  background: #545454;
}

.importnote.disabled,
.importnote-set.disabled {
  color: #9d9d9d;
  border-color: #9d9d9d;
  cursor: not-allowed;
}

.importnote-set.disabled {
  color: white;
  background: #9d9d9d;
}

.nanobar .bar {
  background: #4fd753 !important;
}

.markable {
  cursor: crosshair;
}

.entry-button {
  margin: 10px 0;
}

/*.button-fixed-bottom-left,*/
/*.button-fixed-bottom-right {*/
/*position: fixed;*/
/*bottom: 0;*/
/*width: 50% !important;*/
/*z-index: 1000;*/
/*}*/

/*.button-fixed-bottom-left {*/
/*left: 0;*/
/*}*/
/*.button-fixed-bottom-right {*/
/*right: 0;*/
/*}*/

.darker-menu-entry {
  border-right: 10px solid #8bc34a;
}

.imagesettings {
  z-index: 1000;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
}

#main-body.mobile .imagesettings {
  display: none;
}

.plus-detail {
  color: #8bc34a;
  font-size: 4em;
}
.plus-detail > span {
  display: block;
}

.h2 {
  margin: 0;
  line-height: 100%;
  font-size: 17px;
  font-weight: 400;
}

#main-body.mobile #project-entry-edit {
  margin-top: 0;
}

#main-body.mobile #project-entry-edit > div:nth-child(2) {
  position: fixed;
  bottom: 0;
  width: 100%;
}

#main-body.mobile .mobile-top-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

#main-body.mobile .mobile-top-fixed > button {
  width: 45%;
}

.nopic-container {
  border: 1px dashed #aeaeae;
  color: #aeaeae;
  width: 100%;
  padding-bottom: 75%; /* 4:3 */
  position: relative;
  text-align: center;
  overflow: hidden;
}

.page-depth-child-preview .nopic-container {
  min-width: 114px;
}

.nopic-container > div {
  position: absolute;
  height: 100%;
  width: 100%;
}

.nopic-container span {
  display: block;
}

.vertical-align-wrapper {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
}

.vertical-align-child {
  display: table-cell;
  vertical-align: middle;
}

.page-depth-children-preview-container {
  display: flex;
  flex-wrap: wrap;
}

.page-depth-child-preview {
  padding: 6px 6px 6px 0px;
  height: 100px;
}

.preview-img {
  max-width: 100%;
  height: 100%;
}

.entry-edit-ellipsis > span {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /*margin-bottom: -15px;*/
}

#transitions-container {
  position: fixed;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 100;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
}

#main-body.mobile .main-menu > li > span,
#main-body.mobile .main-menu > li > a {
  padding: 16px 25px 16px 55px;
}

#main-body.mobile .main-menu > li > span > i,
#main-body.mobile .main-menu > li > a > i {
  left: 15px;
}

#main-body.mobile .sub-menu ul > li > a {
  padding: 8px 25px 8px 50px;
}

.main-menu .sub-menu.non-collapsible > a:before,
.main-menu .sub-menu.non-collapsible > a:after {
  content: '';
}

#main-body.mobile header .hm-icon {
  font-size: 34px;
}

#main-body.mobile .list-group.lg-alt .list-group-item {
  padding: 10px 10px;
}

.entry-margin-5 {
  margin: 10px 0 0 0;
}

#main-body.mobile .entry-margin-5 {
  margin: 10px 0 !important;
}

.more-button-column {
  text-align: right;
}

#main-body.mobile .more-button-column {
  position: absolute;
  text-align: right;
  right: 6px;
  top: 6px;
  padding: 0 0 0 15px !important;
}

#main-body.desktop .more-button-column,
.fix-dropdown {
  position: absolute;
  right: 6px;
}

#main-body.mobile .more-button-column {
  position: absolute;
  right: 25px;
  top: 5px;
}

#main .dropdown .dropdown-menu {
  position: inherit;
}

/* at bottom of css !! so it doesn't get overridden */

.main-menu li.active > a,
.main-menu li > a.active {
  color: #d96d00;
}

.tab-nav > li > a:after {
  background: #f57d00;
}

.panel-group .panel-collapse .panel-heading.active .panel-title > a:after {
  background: #f57d00;
}

.page-loader {
  background: #f57d00;
}

#projects-main {
  min-height: 80%;
}

.login button[type='submit'] {
  background: #f57d00;
}

.text-editable,
.text-selectpicker {
  border-bottom: 1px dashed;
  cursor: pointer;
  display: inherit;
}

.h-search {
  background: #f57d00;
}

.picture-switch {
  width: 225px;
  margin: auto;
}

.switch label input[type='checkbox']:checked + .lever {
  background-color: #f57d00;
}
.switch label .lever {
  content: '';
  display: inline-block;
  position: relative;
  width: 40px;
  height: 15px;
  background-color: #818181;
  border-radius: 15px;
  margin-right: 10px;
  transition: background 0.3s ease;
  vertical-align: middle;
  margin: 0 16px;
}
.switch,
.switch * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

.switch label {
  cursor: pointer;
}
.switch,
.switch * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}
.switch label input[type='checkbox'] {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  left: -9999px;
}

.switch label input[type='checkbox']:checked + .lever:after {
  background-color: #f57d00;
  left: 24px;
}
.switch label .lever:after {
  content: '';
  position: absolute;
  display: inline-block;
  width: 21px;
  height: 21px;
  background-color: #f1f1f1;
  border-radius: 21px;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
  left: -5px;
  top: -3px;
  transition:
    left 0.3s ease,
    background 0.3s ease,
    box-shadow 0.1s ease;
}

.custom-select {
  border: 0;
  width: 100%;
  border-bottom: 1px solid #e0e0e0 !important;
  padding: 4px 0;
  cursor: pointer;
  background-color: inherit;
}

.custom-select:disabled {
  color: #9d9d9d;
  cursor: not-allowed;
}

.inside-full-height {
  /*
  // if you want to give content full height give him height: 100%;
  // with content full height you can't apply margins to the content
  // content full height does not work in ie http://stackoverflow.com/questions/27384433/ie-display-table-cell-child-ignores-height-100
  */
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

/* columns of same height styles */

.row-height {
  display: table;
  table-layout: fixed;
  /*height: 100%;*/
  width: 100%;
}
.col-height {
  display: table-cell;
  float: none;
  height: 100%;
  vertical-align: middle;
}

.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.navigate {
  vertical-align: middle;
  color: #aeaeae;
  text-align: center;
}
.navigate:hover {
  background: #f0f0f0;
  color: inherit;
  /*border: 1px solid #f0f0f0;*/
}

.navigate-to-parent {
  font-size: 5em;
}

.navigate-to-children {
  font-size: 3em;
}

.navigate-to-entry {
  font-size: 2em;
}

.has-children {
  color: rgb(94, 94, 94);
}

.navigate-up,
.navigate-down {
  font-size: 4rem;
}

/* text-overflow-ellipsis for buttons */
button {
  text-overflow: ellipsis;
}

.form-control {
  background: none;
}

.checkbox input[disabled] + .input-helper:before,
.radio input[disabled] + .input-helper:before {
  border-color: lightgrey;
}

.checkbox input[disabled]:checked + .input-helper:before,
.radio input[disabled] + .input-helper:after {
  background: lightgrey;
}

.main-menu > li > ul > li > a > i {
  font-size: 18px;
  width: 25px;
}
.dropdown-menu > li > a[disabled] {
  cursor: not-allowed;
  color: lightgrey;
  pointer-events: none;
}

.dropdown-menu > li > div {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #5e5e5e;
  white-space: nowrap;
}

.dropdown-menu > li > div:hover,
.dropdown-menu > li > div:focus {
  text-decoration: none;
  color: #333333;
  background-color: rgba(0, 0, 0, 0.075);
}
.dropdown-menu > li > div {
  padding: 10px 20px;
  -webkit-transition: background-color;
  -o-transition: background-color;
  transition: background-color;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}

.dropdown-menu.dm-icon > li > div > .fa,
.dropdown-menu.dm-icon > li > div > .far,
.dropdown-menu.dm-icon > li > div > .fal,
.dropdown-menu.dm-icon > li > div > .fas {
  line-height: 100%;
  vertical-align: top;
  font-size: 18px;
  width: 28px;
}

.dropdown-menu > li > div.disabled {
  opacity: 0.65;
}

@media (max-width: 991px) {
  #header {
    margin-top: 0;
    margin-bottom: 0;
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.3);
    height: 135px;
  }

  #main-body.mobile:before {
    height: auto;
  }

  .card {
    border-radius: 0;
    box-shadow: inherit;
  }

  .sticky {
    position: -webkit-sticky;
    position: sticky;
  }

  #footer {
    display: none;
  }

  .breadcrumb-main {
    margin: 0;
    border-radius: 0;
  }
}

@media (max-width: 767px) {
  #header {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 10px;
    padding-right: 10px;
    height: 55px;
  }

  .h-search {
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .hs-input {
    border-radius: 0;
  }

  .breadcrumb-main {
    font-size: 1.6rem;
    height: 36px;
  }

  .breadcrumb-entry {
    padding: 6px;
  }

  .navigate-down,
  .navigate-up {
    font-size: 2.8rem;
  }
}

.property.form-horizontal {
  margin-top: 20px;
}

.panel-group:not([data-collapse-color])
  .panel-collapse
  .panel-heading.active
  .panel-title
  > a:after {
  background: #26a69a;
}

.strong {
  font-weight: bold;
}

ul.dropdown-menu {
  max-height: calc(100vh - 222px);
  overflow-y: auto;
}

.modal-open {
  overflow: visible;
}
