:root {
  --record-it-safe-area-inset-top: var(
    --android-safe-area-inset-top,
    env(safe-area-inset-top, 0px)
  );
  --record-it-safe-area-inset-right: var(
    --android-safe-area-inset-right,
    env(safe-area-inset-right, 0px)
  );
  --record-it-safe-area-inset-bottom: var(
    --android-safe-area-inset-bottom,
    env(safe-area-inset-bottom, 0px)
  );
  --record-it-safe-area-inset-left: var(
    --android-safe-area-inset-left,
    env(safe-area-inset-left, 0px)
  );
}
