.record-it-marking {
  border: 3px solid orange;
  width: 16px;
  height: 16px;
  left: 50%;
  top: 50%;
  margin-left: -8px;
  margin-top: -8px;
  position: absolute;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.record-it-current-marking {
  border: 3px solid red !important;
}

.record-it-reference-marking {
  background: orange;
}

.record-it-marking-picture-opacity {
  opacity: 0.4;
}
