/*
Example:
  <div class="record-it-picture-selected-icon record-it-badge ${showSelectIcon ? 'record-it-picture-selected-icon--visible' : ''} ${pictureSelected ? 'record-it-picture-selected-icon--selected' : ''}">
    <custom-icon icon-type="far" icon-name="fa-check"></custom-icon>
  </div>
*/

.record-it-picture-selected-icon {
  position: absolute;
  bottom: 8px;
  right: 8px;
  background-color: rgba(255, 255, 255, 0);
  color: white;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  pointer-events: none;

  opacity: 0;
  transition:
    0.3s ease background-color,
    0.3s ease opacity;

  display: flex;
  align-items: center;
  justify-content: center;
}

.record-it-picture-selected-icon:after {
  content: '';
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  border-radius: 50%;
  border: 2px solid white;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
}

.record-it-picture-selected-icon--visible {
  opacity: 1;
}

.record-it-picture-selected-icon--selected {
  background-color: var(--record-it-color-primary);
  border-color: transparent;
}
