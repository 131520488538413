/*
the container will show the label + the input side by side on bigger screens, and will stack them on smaller widths

Example
  <div class="record-it-input-and-label-container">

    <div class="record-it-input-and-label-container-label">...</div> <!-- optional, label doesn't need to be present -->

    <div class="record-it-input-and-label-container-input-wrapper">

      <floating-label-input class="record-it-input-and-label-container-input" ...>

        ...

      </floating-label-input>

      <div class="record-it-icon far fa-crosshair"></div> <!-- optional, e.g. an icon or a button -->
    </div>

  </div>

alternative stylings/themes

record-it-input-and-label-container_stacked - will stack the label on top of the input element
record-it-input-and-label-container_normal-case - will apply no text-transform

*/
.record-it-input-and-label-container {
  display: flex;
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
}

.record-it-input-and-label-container-label {
  flex: 0 1 180px;
  padding-right: 20px;
  text-transform: uppercase;
  color: var(--record-it-color-gray-600);
  font-size: 13px;
  hyphens: auto;
}

.record-it-input-and-label-container-label--HasIncrementButton {
  flex: 0 1 320px;
  word-break: break-all;
}

.record-it-input-and-label-container-label--IncrementButtonContainer {
  display: flex;
  gap: 20px;
}

.record-it-input-and-label-container-input-wrapper {
  flex: 1 1 300px;
  min-width: 80px;
  display: flex;
}

.record-it-input-and-label-container-input {
  flex: 1 1 auto;
  min-width: 0;
}

.record-it-input-and-label-container-input--OverflowingTableElement {
  width: 100%;
}

.record-it-input-and-label-container-input + * {
  margin-left: 47px;
}

@media (max-width: 767px) {
  .record-it-input-and-label-container-input + * {
    margin-left: 10px;
  }
}

/*
you can add this class to the record-it-input-and-label-container if you plan to put multiple fields after another
 */
.record-it-input-field {
  margin: 25px 0;
}

.record-it-input-field-no-margin-top {
  margin: 0 0 25px 0;
}

.record-it-input-field-no-margin-bottom {
  margin: 25px 0 0 0;
}

/* stacked styling */

.record-it-input-and-label-container_stacked
  .record-it-input-and-label-container-label {
  width: auto;
  flex: 1 1 100%;
  min-width: 0;
}

/* default case */

.record-it-input-and-label-container_normal-case
  .record-it-input-and-label-container-label {
  text-transform: unset;
}
