:root {
  --record-it-button-vertical-padding: 14px;
}

.record-it-button {
  background: #afafaf;
  border: 0;
  border-radius: 2px;
  text-transform: uppercase;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12);
  color: white;
  font-size: 13px;
  letter-spacing: 0.9px;
  font-weight: 500;
  padding-top: 12px;
  padding-bottom: 9px;
  padding-right: var(--record-it-button-vertical-padding);
  padding-left: var(--record-it-button-vertical-padding);
  line-height: normal;
  white-space: nowrap;
  user-select: none;
  overflow: hidden;
}

.record-it-button:hover {
  background: #9c9c9c;
}

.record-it-button.keyboard-focus:focus {
  background: #9c9c9c;
  box-shadow: 0px 0px 0px 2px var(--record-it-color-primary);
}

.record-it-button-primary,
.record-it-button-orange {
  background: var(--record-it-color-primary-light);
}

.record-it-button-primary:hover,
.record-it-button-orange:hover {
  background: var(--record-it-color-primary);
}

.record-it-button-primary.keyboard-focus:focus,
.record-it-button-orange.keyboard-focus:focus {
  background: var(--record-it-color-primary);
  box-shadow: 0px 0px 0px 2px var(--record-it-color-primary-light);
}

.record-it-button-red {
  background: #de4b3b;
}

.record-it-button-red:hover {
  background: #dc311e;
}

.record-it-button-light-blue {
  background: #03a9f4;
}

.record-it-button-light-blue:hover {
  background: #0c8cc7;
}

.record-it-button-light-green {
  background: #8bc34a;
}

.record-it-button-light-green:hover {
  background: #72a03d;
}

.record-it-button:disabled,
.record-it-floating-button:disabled {
  background: var(--record-it-color-gray-500);
  pointer-events: none;
}

/*
and extension of the record-it-button
if you want to have an icon with a text combined, make sure the text is wrapped in a span
Example:
  <button class="record-it-button record-it-icon-button">
    <custom-icon ....></custom-icon>
    <span>additional text</span>
  </button>
*/
.record-it-icon-button {
  padding-top: 6px;
  padding-bottom: 6px;
}

.record-it-icon-button > * {
  vertical-align: middle;
}

/*
extension of the record-it-button
Use if you want a button having an icon combined with text.
You can use both an icon before and after the text.
Example:
  <button class="record-it-button record-it-icon-button record-it-icon-text-button">
    <custom-icon ....></custom-icon>
    <span>additional text</span>
    <custom-icon ....></custom-icon>
  </button>
*/
.record-it-icon-text-button > span {
  margin-left: 5px;
  margin-right: 5px;
}

/* an extension of the record-it-button */
.record-it-button-inline-icon {
  background: transparent;
  color: var(--record-it-color-secondary);
  text-transform: initial;
  box-shadow: none;
  border: 0;
  font-weight: normal;
  position: relative;
}

.record-it-button-inline-icon > * {
  vertical-align: middle;
}

.record-it-button-inline-icon:disabled {
  background: transparent;
  opacity: 0.6;
}

.record-it-button-inline-icon:hover {
  background: transparent;
}

.record-it-button-inline-icon:focus {
  background: transparent;
  outline: 2px solid var(--record-it-color-primary) !important;
}

.record-it-floating-button {
  position: fixed;

  bottom: calc(33px + var(--record-it-safe-area-inset-bottom));
  right: calc(12px + var(--record-it-safe-area-inset-right));

  border-radius: 50%;
  border: none;
  color: white;
  font-size: 20px;
  line-height: 20px;
  width: 4rem;
  height: 4rem;
  z-index: 1;
  box-shadow:
    0 2px 5px rgba(0, 0, 0, 0.16),
    0 2px 10px rgba(0, 0, 0, 0.12);

  text-overflow: clip;
}

.record-it-seamless-button {
  background: transparent;
  color: var(--record-it-color-gray-600);
  text-transform: initial;
  box-shadow: none;
  display: inline-flex;
  align-items: center;
}

.record-it-seamless-button:hover,
.record-it-seamless-button:focus {
  background: transparent;
}

.record-it-seamless-button:hover {
  color: var(--record-it-color-primary);
}

.record-it-seamless-button_activated {
  color: var(--record-it-color-primary);
}

.record-it-seamless-button_left-aligned {
  margin-left: calc(var(--record-it-button-vertical-padding) * -1);
}

/* record-it-hollow-button */

.record-it-hollow-button {
  border: 1px solid var(--record-it-color-primary);
  color: var(--record-it-color-primary);
  background: none;
}

.record-it-hollow-button:hover {
  background: var(--record-it-color-primary);
  color: white;
}

.record-it-seamless-button:disabled,
.record-it-hollow-button:disabled {
  background: none;
  opacity: 0.4;
}

/* record-it-badge-button */

.record-it-badge-button {
  background: transparent;
  color: var(--record-it-color-gray-600);
  box-shadow: none;
  border: 1px solid transparent;
}

.record-it-badge-button:hover:not(.record-it-badge-button_activated) {
  color: var(--record-it-color-primary);
  background: none;
  border: 1px solid var(--record-it-color-primary);
}

.record-it-badge-button_activated {
  color: white;
  background-color: var(--record-it-color-primary);
}

.record-it-button-group {
  position: relative; /* needed currently for dropdown TODO: fix this */
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12);
  display: inline-flex;
}

.record-it-button-group > .record-it-button {
  position: relative;
  box-shadow: none;
  vertical-align: top;
}

.record-it-button-group
  > .record-it-button:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}

.record-it-button-group > .record-it-button:first-child:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.record-it-button-group > .record-it-button:last-child:not(:first-child),
.record-it-button-group > .dropdown-toggle:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

/* the container is useful if you have multiple buttons and you also want to allow them to break up into multiple lines */
.record-it-button-container {
  margin-top: -5px;
  margin-left: -5px;
}

.record-it-button-container > .record-it-button {
  margin-top: 5px;
  margin-left: 5px;
}

/*
  required markup for this style:
    <div class="record-it-button-group record-it-dropdown-button">
      <button class="record-it-button"></button>
      <button class="record-it-button record-it-dropdown-button-icon-button">
        <i class="record-it-icon fal fa-chevron-down"></i>
      </button>
    </div>
 */

.record-it-dropdown-button
  .record-it-button:not(.record-it-dropdown-button-icon-button) {
  padding-right: 7px;
}

.record-it-dropdown-button-icon-button {
  padding: 0 5px 0 5px;
  position: relative;
}

.record-it-dropdown-button-icon-button > .record-it-icon {
  font-size: 16px;
}

/*
  an alternative to a normal button, apply this to a <a> element
 */
.record-it-link-button,
.record-it-link-button:visited,
.record-it-link-button:hover,
.record-it-link-button:focus {
  display: inline-block;
  padding: 2px;
  color: var(--record-it-color-secondary);
  text-decoration: underline;
  border: 1px dashed transparent;
}

.record-it-link-button:focus {
  border-color: var(--record-it-color-secondary);
}

.record-it-link-button:hover {
  color: black;
  text-decoration: none;
}

/* a button bar specific for adding entities, use case e.g. for for lists in the middle of the page */
.record-it-add-button-bar {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.record-it-add-button-bar_bottom {
  margin-top: 15px;
  margin-bottom: 0;
}

.record-it-add-button-bar_left {
  justify-content: flex-start;
}

.record-it-add-button-bar > .record-it-button {
  flex: 0 0 auto;
}

/*
 for a select element in the button bar (e.g. for selecting and adding a person)

  <div class="record-it-add-button-bar">
    <custom-select ... class="record-it-sub-button-bar-select"></custom-select>
    <button class="record-it-button record-it-button-primary">Hinzufügen</button>
  </div>
 */
.record-it-add-button-bar-select {
  flex: 1 1 auto;
  margin-right: 20px;
}

.record-it-add-button-bar-select.record-it-input-and-label-container {
  width: auto;
}

@media all and (max-width: 767px) {
  .record-it-add-button-bar {
    display: block;
    text-align: right;
  }

  .record-it-add-button-bar-select {
    margin-right: 0;
    margin-bottom: 7px;
  }
}
