:root {
  --sidebar-width: 300px;
}

.record-it-sidebar {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  width: calc(var(--sidebar-width) + var(--record-it-safe-area-inset-left));

  background-color: var(--record-it-color-gray-200);
  box-shadow:
    0 16px 16px 0 rgba(0, 0, 0, 0.24),
    0 0 16px 0 rgba(0, 0, 0, 0.18);
}

.record-it-sidebar-heading {
  background: var(--record-it-color-gray-800);

  padding-top: var(--record-it-safe-area-inset-top);
  padding-left: calc(28px + var(--record-it-safe-area-inset-left));
  padding-bottom: 0px;
  padding-right: calc(28px + var(--record-it-safe-area-inset-right));

  height: calc(80px + var(--record-it-safe-area-inset-top));
  line-height: 80px;
  flex: 0 0 calc(80px + var(--record-it-safe-area-inset-top));
  overflow: hidden;
  white-space: nowrap;
}

.record-it-sidebar-heading-close-button {
  color: white;
  cursor: pointer;
  vertical-align: baseline;
}

.record-it-sidebar-heading-main-caption {
  margin-left: 23px;
}
