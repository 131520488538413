.record-it-message-box {
  padding: 15px;
  border-width: 1px;
  border-style: solid;
}

.record-it-message-box-margin-bottom {
  margin-bottom: 47px;
}

.record-it-message-box-warning {
  border-color: orange;
  color: #dc8600;
  background: #fff3de;
}

.record-it-message-box-error {
  border-color: red;
  color: #db0000;
  background: #ffdede;
}

.record-it-message-box-info {
  border-color: #b8daff;
  color: #004085;
  background: #cce5ff;
}
