.record-it-entity-list-item-container {
  margin-bottom: 10px;
  display: block;
  user-select: none;
}

.record-it-entity-list-item {
  height: 100px;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0 var(--record-it-color-gray-300);
  display: flex;
  align-items: center;
  position: relative;
  font-size: 13px;
  color: #878787;
  line-height: 13px;
  transition: background-color 0.3s ease;
}

.record-it-entity-list-item:hover {
  box-shadow: 0 2px 7px 0 rgba(196, 196, 196, 0.56);
}

/* can't have a second line! */
.record-it-entity-list-item-compact {
  height: 40px;
  align-items: center;
}

.record-it-entity-list-item_highlighted,
.record-it-entity-list-item_highlighted:hover {
  background-color: rgba(246, 125, 0, 0.6);
}

.record-it-entity-list-item_more-panel-open {
  box-shadow: 0 1px 4px 0 #d5d5d5;
  border-radius: 3px 3px 0 0;
}

.record-it-entity-list-item_half-transparent {
  color: #ddd;
  opacity: 0.6;
}

.record-it-entity-list-item-button {
  text-align: center;
  align-self: stretch;
  position: relative;
}

.record-it-entity-list-item-button:last-child {
  flex: 0 0 100px;
}

/* can't have a second line! */
.record-it-entity-list-item-compact {
  height: 40px;
  align-items: center;
}

.record-it-entity-list-item-button {
  flex: 0 0 90px;
}

.record-it-entity-list-compact-items .record-it-entity-list-item-button,
.record-it-entity-list-item-compact .record-it-entity-list-item-button {
  flex: 0 0 40px;
}

.record-it-entity-list-item-button .record-it-icon {
  transform: translate(-50%, -50%);
  top: 50%;
  position: absolute;
  left: 50%;
}

.record-it-entity-list-item-button:hover,
.record-it-entity-list-item-more-button_active {
  color: var(--record-it-color-primary);
}

.record-it-entity-list-item-more-button_active::after {
  content: '';
  border-top: 10px solid white;
  position: absolute;
  top: 100%;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  left: 50%;
  transform: translateX(-50%);
}

.record-it-entity-list-item-column {
  padding-left: 25px;
  padding-right: 25px;
  width: 100%;
  white-space: nowrap;
  position: relative;
  min-width: 0;
  line-height: 2rem;
}

.record-it-entity-list-item-column-compact {
  flex: 50%;
  min-width: 100px;
}

.record-it-entity-list-item-column-huge {
  flex: 200%;
}

.record-it-entity-list-item-column-valueCalculation {
  flex: 25%;
  min-width: 130px;
}

.record-it-entity-list-item-column-selectionIcon {
  min-width: 50px;
}

.record-it-entity-list-item-column-date {
  max-width: 175px;
}

.record-it-entity-list-item-column-distance {
  max-width: 175px;
}

.record-it-entity-list-item-column > * {
  text-overflow: ellipsis;
  overflow: hidden;
}

.record-it-entity-list-item-container .record-it-entity-list-item-column > * {
  display: block;
}

.record-it-entity-list-item-title {
  font-size: 17px;
  color: var(--record-it-color-secondary);
}

.record-it-entity-list-item-2nd-line {
  position: absolute;
  width: 100%;
  text-overflow: ellipsis;
  padding: 0 25px;
  left: 0;
  color: #bdbdbd;
  line-height: 1rem;
}

.record-it-entity-list-header {
  display: flex;
  color: var(--record-it-color-gray-600);
  margin-bottom: 7px;
  align-items: center;
  text-transform: uppercase;
  user-select: none;
}

.record-it-entity-list-header > .record-it-entity-list-item-column {
  overflow: hidden;
  text-overflow: ellipsis;
}

.record-it-entity-list-item-status-icons {
  flex: 0 0 75px;
  text-align: center;
}

.record-it-entity-list-item-picture {
  padding: 0;
  width: 110px;
  flex: 0 0 auto;
  height: 100%;
  border-radius: 3px 0 0 3px;
  background: var(--record-it-color-gray-100);
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.record-it-entity-list-item-column-checkbox {
  flex: 0 0 auto;
  width: auto;
  line-height: 0;
  padding-left: 15px;
  padding-right: 15px;
}

.record-it-entity-list-item-column-checkbox
  + .record-it-entity-list-item-column {
  padding-left: 0px;
}

/* an extension/flavor of the .record-it-entity-list-item-picture */
.record-it-entity-list-item-picture-square {
  width: 100px;
}

.record-it-entity-list-item-picture > picture-preview {
  width: 100%;
  height: 100%;
  border-radius: 3px 0 0 3px;
}

.record-it-entity-list-item-picture_more-panel-open > picture-preview {
  border-radius: 3px 0 0 0;
}

.record-it-entity-list-item-picture > i.record-it-icon {
  font-size: 36px;
  width: 36px;
  height: 36px;
  line-height: 36px;
}

.record-it-entity-list-item-color-bar {
  padding: 0;
  flex: 0 0 8px;
  height: 100%;
  background-color: white;
  border-radius: 3px 0 0 3px;
}

.record-it-entity-list-item-color-bar_more-panel-opened {
  border-radius: 3px 0 0 0;
}

.record-it-entity-list-active-sort-column {
  font-weight: 600;
}

.record-it-entity-list-active-sort-column > i {
  margin-left: 10px;
  font-size: 10px;
  font-weight: 600;
  display: inline-block;
}

.record-it-entity-list-no-items-text {
  font-size: 13px;
  padding: 20px 10px;
  text-align: center;
  font-weight: 500;
}

@media (max-width: 767px) {
  .record-it-entity-list-item-container {
    margin-bottom: 5px;
  }

  .record-it-entity-list-item {
    height: 70px;
    font-size: 11px;
  }

  .record-it-entity-list-item-compact {
    height: 40px;
  }

  .record-it-entity-list-item-button,
  .record-it-entity-list-item-button:last-child {
    flex: 0 0 40px;
  }

  .record-it-entity-list-item-button .record-it-icon {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
  }

  .record-it-entity-list-item-title {
    font-size: 14px;
  }

  .record-it-entity-list-item-2nd-line {
    position: initial;
    padding: 0;
    margin-top: 4px;
  }

  .record-it-entity-list-item-column {
    padding-left: 11px;
    padding-right: 11px;
    line-height: initial;
    height: initial;
  }

  .record-it-entity-list-item-column-valueCalculation {
    min-width: 100px;
  }

  .record-it-entity-list-header {
    font-size: 12px;
  }

  .record-it-entity-list-item-picture {
    padding: 0;
    width: 70px;
    height: 100%;
  }

  .record-it-entity-list-item-picture > i.record-it-icon {
    font-size: 28px;
  }
}

/*
  the subtle-entity-list-item is a list item to integrated e.g. in the middle of input fields (best wrapped in a expandable-container)
  no entity-list-item class is compatible with the subtle one!
*/
.record-it-subtle-entity-list-item {
  transition: background-color 0.3s ease;
  border-top: 1px solid var(--record-it-color-gray-500);
  padding-top: 20px;
  padding-bottom: 20px;
}

.record-it-subtle-entity-list-item_bottom-border {
  border-top: none;
  border-bottom: 1px solid var(--record-it-color-gray-500);
}

/*
  this class will extend the background a little bit to the left/right so the content will not stick to the resulting border

  you only need this if your list item has a background color
*/
.record-it-subtle-entity-list-item_with-extended-background {
  margin-left: -7px;
  margin-right: -7px;
  padding-left: 7px;
  padding-right: 7px;
}

.record-it-subtle-entity-list-item-bottom-button-bar {
  text-align: right;
}
