/*
 a simple white card with a box shadow
 Example:
    <div class="record-it-card">
      <div class="record-it-card-small-header">
        Max Mustermann
      </div>
      <div class="record-it-card-content">
        .... comment etc. ...
      </div>
    </div>
*/
.record-it-card {
  box-shadow: none;
  background: white;
  box-shadow: 0 1px 4px 0 var(--record-it-color-gray-300);
  border-radius: 3px;
  font-size: 16px;
  margin-bottom: 10px;

  padding: 18px 12px;
}

.record-it-card-small-header {
  font-size: 13px;
  color: var(--record-it-color-gray-600);
  margin-top: -10px;
  margin-bottom: 8px;
}

.record-it-card-content {
}

.record-it-card-footer {
  font-size: 13px;
  margin-top: 8px;
  margin-bottom: -10px;
}
