.record-it-custom-select-select-wrapper {
  display: flex;
  position: relative;
  border: 1px solid transparent;
  border-bottom: 1px solid var(--record-it-color-gray-400);
  cursor: pointer;
  height: 100%;
}

.record-it-custom-select-select-wrapper::after {
  display: block;
  content: ' ';
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: none;
  transform: scaleX(0);
  transition:
    transform 0.3s,
    opacity 0.3s;
  opacity: 0;
}

.record-it-custom-select-select-wrapper-focused::after {
  transform: scaleX(1);
  border-bottom: 2px solid var(--record-it-color-primary);
  opacity: 1;
}

.record-it-custom-select-select-wrapper-open {
  border: 1px solid var(--record-it-color-gray-300);
  border-bottom-color: transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.record-it-custom-select-select-wrapper-disabled {
  color: var(--record-it-color-gray-700);
  cursor: not-allowed;
}

.record-it-custom-select-options-list {
  background: white;
  border: 1px solid var(--record-it-color-gray-300);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  overflow: auto;
  overscroll-behavior: contain;
  min-width: 200px;
}

.record-it-custom-select-options-item {
  min-height: 40px;
  cursor: pointer;
  padding: 8px 10px;
  margin: 0;

  border-bottom: 1px solid var(--record-it-color-gray-300);

  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.record-it-custom-select-options-item:last-child {
  border-bottom: 0;
}

.record-it-custom-select-options-item-highlighted {
  background: var(--record-it-color-gray-400);
}

.record-it-custom-select-options-item-selected {
  background: var(--record-it-color-gray-600);
}

.record-it-custom-select-caret {
  align-self: center;
  flex: 0 0 auto;
  width: 20px;
  text-align: center;
}

.record-it-custom-select-options-item-label {
  flex: 1 1 100%;

  overflow: inherit;
  text-overflow: inherit;
  overflow-wrap: break-word;
}

.record-it-custom-select-options-item-sub-label {
  color: var(--record-it-color-gray-700);
  font-size: 13px;

  overflow: inherit;
  text-overflow: inherit;
}
