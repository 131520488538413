.record-it-radiobutton-group {
  margin-top: -10px;
  margin-left: -20px;
}

.record-it-radiobutton-group > * {
  margin-top: 10px;
  margin-left: 20px;
  display: inline-block;
}
