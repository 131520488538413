/*
a style for centering a picture and having buttons displayed on it's side

Example:
  <div class="record-it-centered-picture-wrapper">
    <div class="record-it-centered-picture-left-column"></div>

    <div class="record-it-centered-picture-picture-column">
      <picture picture.to-view="_pictureModel"></picture>
      <div if.to-view="!_pictureModel"
           class="record-it-centered-picture-not-found-placeholder">
        <div class="record-it-centered-picture-not-found-placeholder-text">
          Bild konnte nicht gefunden werden.
        </div>
      </div>
    </div>

    <div class="record-it-centered-picture-button-column">
      <div class="record-it-centered-picture-desktop-details">
        <div class="record-it-centered-picture-detail-title">Erstellt am</div> <!-- same content as in the mobile details, it's advised to extract the content html and include it via a <compose> -->
        <div class="record-it-centered-picture-detail-description">17.08.2018</div>
      </div>

      <div class="record-it-centered-picture-buttons-wrapper">
        <button class="record-it-button"></button><br>
        <button class="record-it-button"></button>
      </div>

      <div class="record-it-centered-picture-mobile-details">
        <div class="record-it-centered-picture-detail-title">Erstellt am</div> <!-- same content as in the desktop details, it's advised to extract the content html and include it via a <compose> -->
        <div class="record-it-centered-picture-detail-description">17.08.2018</div>
      </div>
    </div>
  </div>
*/

.record-it-centered-picture-wrapper {
  display: flex;
  flex-flow: row nowrap;
}

.record-it-centered-picture-button-column,
.record-it-centered-picture-left-column {
  flex: 1 0 150px;
}

.record-it-centered-picture-picture-column {
  flex: 20 1 auto;
}

.record-it-centered-picture-button-column {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;

  padding-left: 32px;
}

.record-it-centered-picture-buttons-wrapper > button ~ button {
  margin-top: 2px;
}

.record-it-centered-picture-desktop-details {
  flex: 1 1 auto;
}

.record-it-centered-picture-mobile-details {
  display: none;
}

.record-it-centered-picture-detail-title {
  margin-top: 17px;
  font-size: 13px;
  color: var(--record-it-color-gray-600);
}

.record-it-centered-picture-detail-description {
  color: var(--record-it-color-secondary);
  font-size: 14px;
}

.record-it-centered-picture-not-found-placeholder {
  position: relative;
  padding-bottom: 75%;
  background: var(--record-it-color-gray-300);
}

.record-it-centered-picture-not-found-placeholder-text {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
  transform: translate(-50%, -50%);
}

@media all and (max-width: 767px) {
  .record-it-centered-picture-wrapper {
    display: block;
  }

  .record-it-centered-picture-left-column {
    display: none;
  }

  .record-it-centered-picture-button-column {
    margin-top: 16px;
    display: block;
    padding-left: 0;
    justify-content: flex-end;
  }

  .record-it-centered-picture-desktop-details {
    display: none;
  }

  .record-it-centered-picture-mobile-details {
    display: block;
    margin-top: 17px;
  }

  .record-it-centered-picture-buttons-wrapper {
    text-align: right;
  }

  .record-it-centered-picture-buttons-wrapper > br {
    display: none;
  }

  .record-it-centered-picture-buttons-wrapper > button {
    margin-top: 0;
    margin-left: 3px;
  }
}
