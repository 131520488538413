/* apply this on the element where the ProcessConfigurationStepIconCustomAttribute is used */
.record-it-operations-step-icon-wrapper {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
}

.record-it-operations-step-icon-wrapper > .record-it-icon {
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 16px;
}

.record-it-operations-step-icon-wrapper > span {
  line-height: 29px;
  font-size: 16px;
  vertical-align: top;
  font-weight: 500;
}

.record-it-operations-step-icon-warning {
  position: absolute;
  top: -4px;
  right: -2px;

  font-size: 10px;
  color: var(--record-it-color-red);
}

.record-it-operations-not-connected-warning {
  margin-bottom: 47px;
}

.record-it-operations-expandable-container-add-button-wrapper {
  margin-top: 60px;
}

/*
  a wrapper for multiple checkboxes

  Example:
    <div class="record-it-operations-process-task-selection-wrapper">
      <div class="record-it-operations-process-task-selection-wrapper-checkbox-wrapper">
        <custom-checkbox></custom-checkbox>
      </div>
    </div>
*/
.record-it-operations-process-task-selection-wrapper {
  margin-left: -47px;
  margin-top: -15px;
}

.record-it-operations-process-task-selection-wrapper-checkbox-wrapper {
  display: inline-block;
  margin-left: 47px;
  margin-top: 15px;
}
