/*
if you change any color which will affect the theming colors, you should also change it in the ThemingService
*/
:root {
  --record-it-color-black: #000;
  --record-it-color-gray-800: #4a4a4a;
  --record-it-color-gray-700: #939393;
  --record-it-color-gray-600: #a5a5a5;
  --record-it-color-gray-500: #d8d8d8;
  --record-it-color-gray-400: #dcdcdc;
  --record-it-color-gray-300: #eaeaea;
  --record-it-color-gray-200: #f5f5f5;
  --record-it-color-gray-100: #fafafa;
  --record-it-color-white: #fff;

  --record-it-color-light-silver: #c3c3c3;

  --record-it-color-orange: #f67d00;
  --record-it-color-light-orange: #f6a139;
  --record-it-color-red: #ff0000;
  --record-it-color-light-red: #dbbdbd;
  --record-it-color-blue: #03a9f4;
  --record-it-color-sea-green: #3cb371;
  --record-it-color-green: #8bc34a;
  --record-it-color-neon-green: #31d70e;
  --record-it-color-light-green: #cddbbd;

  --record-it-color-disabled: #8a8a8a;

  /*************** PERSON COLORS ****************/
  --record-it-color-highlighted-person-contact-background: #f6f9ce;

  /*************** THEMING COLORS ***************/
  --record-it-color-primary: var(
    --record-it-color-orange
  ); /* color for primary elements which should be branded, e.g. current route in the main menu */
  --record-it-color-secondary: var(
    --record-it-color-gray-800
  ); /* secondary color, e.g. for normal buttons */
  --record-it-color-primary-light: var(
    --record-it-color-light-orange
  ); /* lighter version of the primary color, e.g. for buttons if the primary is too strong */
  --record-it-color-breadcrumb: var(
    --record-it-color-gray-600
  ); /* background color of the breadcrumb */
  --record-it-color-main-page-loader: var(
    --record-it-color-gray-600
  ); /* generally this is the primary color, but it needs to have a different initial value */

  /*************** DEFECT COLORS ***************/
  --defect-picture-border-color: var(--record-it-color-primary);
  --record-it-color-defect-state-open: #d53357;
  --record-it-color-defect-state-processed: #ecb965;
  --record-it-color-defect-state-done: #5dcd5c;

  /*************** MODULE COLORS ***************/
  --record-it-color-module-basic: var(--record-it-color-orange);
  --record-it-color-module-b1300: #5b8ce7;
  --record-it-color-module-inspect: #f0f030;
  --record-it-color-module-via: #40c5a3;

  /*************** GENERALLY USED COLOR VARIABLES ***************/
  --record-it-color-half-transparent-background: rgba(255, 255, 255, 0.42);
}
