.record-it-filter-multi-filter-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  justify-items: flex-start;
  row-gap: 16px;
  column-gap: 32px;
  margin-bottom: 16px;
}

.record-it-filter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

.record-it-filter-label {
  flex: 0 0 auto;
  margin-right: 4px;
  display: inline-block;
  color: var(--record-it-color-gray-600);
  text-transform: uppercase;
}

.record-it-filter-select-filter {
  flex: 1 0 auto;
  display: inline-block;
  width: 100%;
}

@media (max-width: 830px) {
  .record-it-filter-multi-filter-container {
    padding: 12px;
    justify-items: stretch;
  }
}
