/*
 * Basier Circle Regular
 */
@font-face {
  font-family: 'Basier Circle';
  src: url('../fonts/BasierCircleRegular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

/*
 * Basier Circle Medium
 */
@font-face {
  font-family: 'Basier Circle';
  src: url('../fonts/BasierCircleMedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

/*
 * Basier Circle Bold
 */
@font-face {
  font-family: 'Basier Circle';
  src: url('../fonts/BasierCircleBold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

html,
body {
  font-family: 'Basier Circle', sans-serif;
  color: #4a4a4a;
  font-size: 14px;
}

a:hover,
a:focus {
  text-decoration: underline;
}

#main-page-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;

  background-color: var(--record-it-color-main-page-loader);
  transition: background-color 0.4s ease; /* if you adapt this transition, you also have to adapt the timeout in main.js */
}

#main-page-loader .preloader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

#main-page-loader--InitializingStepsContainer {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  height: 100px;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 5px;
}

.main-page-loader--InitializingStep {
  color: white;
  line-height: 1.2;
}

.main-page-loader--InitializingStep_old {
  opacity: 0.5;
  font-size: 0.8em;
}

/* first content on the page (so you have space between the header and the content) */
.record-it-first-page-content {
  margin-top: 27px;
}

.app-section {
  margin-right: var(--record-it-safe-area-inset-right);
  margin-left: var(--record-it-safe-area-inset-left);
}

.record-it-site-container,
.record-it-paddingless-site-container {
  max-width: 1240px;
  padding-left: 96px;
  padding-right: 96px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
}

/* has the same overall width as the site container, but without any padding (and the content is wider than the normal site container then!)*/
.record-it-paddingless-site-container {
  max-width: 1432px; /* 2 * 96px + 1240px */
  padding: 0;
}

@media (max-width: 1767px) {
  .record-it-site-container {
    padding-left: 47px;
    padding-right: 47px;
  }

  .record-it-paddingless-site-container {
    max-width: 1334px; /* 2 * 47px + 1240px */
  }
}

@media (max-width: 767px) {
  .record-it-site-container {
    padding-left: 5px;
    padding-right: 5px;
  }

  .record-it-paddingless-site-container {
    max-width: 1250px; /* 2 * 5px + 1240px */
  }
}

/*

the dropdown menu also supports icons
example:
   <div class="record-it-dropdown-menu">
    <div>
      <i class="far fa-caret-down"/> <span>down</span>
    </div>
    <div>
      <img class="record-it-dropdown-menu-icon" src="custom-icon.svg"> <span>a Custom icon</span>
    </div
   </div>
 */

.record-it-dropdown-menu {
  min-width: 100px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.24);
}

.record-it-dropdown-menu > * {
  display: block;
  padding: 8px 13px;
  background: var(--record-it-color-gray-100);
}

.record-it-dropdown-menu > *:first-child {
  padding-top: 11px;
}

.record-it-dropdown-menu > *:last-child {
  padding-bottom: 11px;
}

.record-it-dropdown-menu > *,
.record-it-dropdown-menu > a:hover {
  color: #4a4a4a;
}

.record-it-dropdown-menu > *:hover {
  background: #eeeeee;
}

.record-it-dropdown-menu-icon,
.record-it-dropdown-menu > * > .fa,
.record-it-dropdown-menu > * > .far,
.record-it-dropdown-menu > * > .fal,
.record-it-dropdown-menu > * > .fas {
  width: 24px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.record-it-dropdown-menu > * > .fa,
.record-it-dropdown-menu > * > .far,
.record-it-dropdown-menu > * > .fal,
.record-it-dropdown-menu > * > .fas {
  font-size: 1.2em;
}

.record-it-dropdown-menu > * > * {
  vertical-align: middle;
}

.record-it-disabled,
.record-it-disabled:hover,
.record-it-disabled:focus {
  color: #cacaca !important;
  cursor: not-allowed;
  pointer-events: none;
}

/* a small centered card for e.g. the login page*/
.record-it-small-card {
  max-width: 420px;
  padding: 20px 35px 50px 35px;
  box-shadow: 0 1px 4px 0 var(--record-it-color-gray-300);
  background: white;
  margin: 90px auto 0;
  color: #4a4a4a;
}

@media (max-width: 420px) {
  .record-it-small-card {
    box-shadow: none;
    margin-top: 35px;
  }
}

.record-it-card-header {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 25px;
}

.record-it-card-main-button-wrapper {
  margin-top: 45px;
  text-align: center;
}

.record-it-card-main-button-wrapper > button {
  width: 175px;
  max-width: 100%;
}

.record-it-hidden-file-input-container {
  position: relative;
  cursor: pointer;
}

.record-it-hidden-file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.record-it-backdrop-white {
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: rgba(255, 255, 255, 0.26);
}

/* a header to separate multiple section in e.g. a form */
.record-it-section-header {
  border-top: 2px solid #d7d7d7;
  padding-top: 25px;
  text-transform: uppercase;
  color: var(--record-it-color-gray-600);
  margin-top: 30px;
  font-size: 13px;
  letter-spacing: 0.3px;
  margin-bottom: 30px;
}

.record-it-section-header-compact {
  margin-bottom: 15px;
}

/* you can use this e.g. for your first section when you don't need any extra spacing at the top */
.record-it-section-header-align-top {
  border-top: none;
  padding-top: 0;
  margin-top: 0;
}

.record-it-badge {
  display: inline-block;
  background-color: var(--record-it-color-primary);
  color: white;
  border-radius: 1rem;
  padding: 0.1rem 0.5rem;
  white-space: nowrap;
  line-height: 1.2;
  margin: 2px;
}

.record-it-badge > input {
  border: none;
  background: none;
}

/* apply this class to an <ul> element */
.record-it-compact-list {
  margin-bottom: 0px;
  padding-left: 25px;
}

.record-it-input-label {
  font-size: 11px;
  color: var(--record-it-color-gray-600);
}

.record-it-input-label-big {
  font-size: 13px;
}

.record-it-background-highlighted {
  background-color: rgba(246, 125, 0, 0.6);
}

/*
  a class to so style a title input in combination with a compact-clickable-text-input

  example:
  <div class="record-it-compact-title-input-wrapper">
    <compact-clickable-text-input ...></compact-clickable-text-input>
    <div class="record-it-compact-title-input-wrapper-sub-title"></div> <!-- the sub title is optional -->
  </div>
 */
.record-it-compact-title-input-wrapper {
  margin-bottom: 20px;
}

.record-it-compact-title-input-wrapper > compact-clickable-text-input {
  font-weight: 500;
  font-size: 1.9rem;
  line-height: 1.9rem;
}

.record-it-compact-title-input-wrapper-sub-title {
  font-size: 0.9rem;
  color: #878787;
}

.record-it-expandable-container--separator-label {
  font-size: 17px;
  font-weight: 500;
}
