.record-it-request-status-green-icon {
  color: var(--record-it-color-green);
}

.record-it-request-status-red-icon {
  color: var(--record-it-color-red);
}

.record-it-request-status-grey-icon {
  color: var(--record-it-color-gray-600);
}
